<template>
    <div class="article-list-main">
        <div class="article-header">
            <h1>新闻</h1>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="article-nnav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item >新闻</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!--选择区-->
        <div class="select-area">
            <div>
                <el-radio-group v-model="type">
                    <el-radio-button label="全部" name="111"></el-radio-button>
                    <el-radio-button label="信息"></el-radio-button>
                    <el-radio-button label="活动"></el-radio-button>
                    <el-radio-button label="卡牌"></el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <!--列表区-->
        <div>
            <div class="news-list">
                <!--所有文章begin-->
                <div>
                    <div class="news-item" v-for="(v,k) in dataList" :key="k">
                        <router-link :to="'/index/article?id='+v.id">
                            <div class="news-item-sub">
                                <div class="news-item-img">
                                    <el-image
                                            style="width: 100%;height: 100%"
                                            :src="v.banner_img"
                                            fit="scale-down">
                                        >
                                    </el-image>
                                </div>
                                <div class="news-item-lab">
                                    <div>{{$moment(v.created_at).format("YYYY/MM/DD")}}</div>
                                    <div
                                            v-for="(item,index) in $webConfig.articleTypes"
                                            :key="index"
                                            v-show="(item.type == v.type)"
                                            :class="handleLabClass(v.type)"
                                    >
                                        {{item.name}}
                                    </div>
                                </div>
                                <div class="news-item-desc">{{v.schema}}</div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!--所有文章end-->
                <div class="m-clear-both"></div>
            </div>
        </div>
        <!--分页区-->
        <div class="article-pagination">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "list",
        data() {
            return {
                type: '',
                typeVal:0,
                currentPage:'',
                total:100,
                dataList:[]
            }
        },
        methods: {
            handleCurrentChange(val) {
                this.currentPage = val;
            },
            handleLabClass(type) {
                switch (type) {
                    case 1:
                        return "news-item-lab_news";
                    case 2:
                        return "news-item-lab_card";
                    case 88:
                        return "news-item-lab_event";
                    default:
                        return "";
                }
            },
            getList(){
                let page = this.currentPage;
                let type = this.typeVal;
                this.$axios.post("site/article/getIndexList",{page,type})
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.total = res.data.data.total;
                            this.dataList = res.data.data.list;
                        }
                    })
            }
        },
        created() {
            this.getList();
        },
        watch: {
            type(newValue, oldValue) {
                console.log(newValue)
                switch (newValue) {
                    case "全部":
                        this.typeVal = 0;
                        break;
                    case "信息":
                        this.typeVal = 1;
                        break;
                    case "活动":
                        this.typeVal = 2;
                        break;
                    case "卡牌":
                        this.typeVal = 88;
                        break;
                }
                this.getList();
            }
        },
    }
</script>

<style scoped>
    .article-list-main{
        width: 100%;
        height: auto;
        background-color: white;
    }
    .article-header{
        text-align: center;
        padding-top: 20px;
    }
    .article-nnav{
        width: 99%;
        margin-left: auto;
        margin-right: auto;
    }
    .select-area{
        height: 70px;
        background-color: #f0f0f0;
        text-align: center;
        width: 100%;
        line-height: 70px;
        margin-top: 20px;
    }
    .el-radio-button {
        position: relative;
        display: inline-block;
        outline: 0;
        margin: 0 4px;
        border: 1px solid;
    }
    .article-pagination{
        text-align: center;
        padding: 10px 0;
    }


    /*单位列表框*/
    .news-list{
        width: 99%;
        margin:0 auto;
    }
    .news-list > div{
        width: 100%;
        color: white;
    }
    .news-list > div > div{
        width: 48%;
        float: left;
        margin: 1%;
        text-align: center;
    }

    .news-item > a{
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        border: 1px solid #c6c6c6;
    }
    .news-item-img{
        width: 100%;
        height: 23vh;
    }
    .news-item-img img{
        /*width: 100%;
        min-height: 220px;*/
    }
    .news-item-lab > div{
        float: left;
        height: 26px;
        line-height: 26px;
        width: 50%;
    }
    .news-item-lab >div:first-child{
        color: white;
        background-color: #434343;
        text-align: center;
        font-size: 14px;
    }
    .news-item-lab >div{
        color: white;
        text-align: center;
        font-size: 14px;
    }
    .news-item-lab_news{
        background-color: #a21a31;
    }
    .news-item-lab_card{
        background-color: #7f8b00;
    }
    .news-item-lab_event{
        background-color: #0783a9;
    }
    .news-item-desc {
        clear: both;
        text-align: left;
        color: white;
        font-size: 10px;
        background-color: #434343;
        height: 100px;
        line-height: 28px;
        padding: 5px;
        letter-spacing: 1px;
        /* 将对象作为弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 设置或检索伸缩盒对象的子元素的排列方式 。 */
        -webkit-box-orient: vertical;
        /*  用来限制在一个块元素显示的文本的行数 */
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

</style>
